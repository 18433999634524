<template>
    <div class="flex-column">
        <sticky :className="'sub-navbar'">
            <div class="filter-container">
                <base-search  :searchList="$attachmentSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked"></base-search>
            </div>
        </sticky>
        <div class="app-container flex-item">
            <div class="bg-white" style="height: 100%;">
                <el-table ref="mainTable" :key='tableKey' :data="list"  border fit highlight-current-row style="width: 100%;" height="calc(100% - 52px)" @row-click="rowClick" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" align="center" width="55"></el-table-column>
                    <el-table-column min-width="10px" :label="'缩略图'">
                        <template slot-scope="scope">
                            <span v-if="!isFile(scope.row)">
                                <el-avatar :size="50" :src="baseImgUrl + '/'+scope.row.thumbnail"></el-avatar>
                            </span>
                            <span v-else>
                                <el-avatar :size="50" :src="scope.row.thumbnail"></el-avatar>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="30px" :label="'文件名称'">
                        <template slot-scope="scope">
                        <span>{{scope.row.fileName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="50px" :label="'路径'">
                        <template slot-scope="scope">
                        <span>{{scope.row.filePath}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="10px" :label="'文件类型'">
                        <template slot-scope="scope">
                        <span>{{scope.row.fileType}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="120px" :label="'上传人'">
                        <template slot-scope="scope">
                        <span>{{scope.row.createUserName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="200px" :label="'上传时间'">
                        <template slot-scope="scope">
                        <span>{{scope.row.createTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="status-col" :label="'是否可用'" width="100">
                        <template slot-scope="scope">
                        <span :class="scope.row.enable | statusFilter">{{statusOptions.find(u =>u.key ==
                            scope.row.enable).display_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :label="'操作'" width="130" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button type="primary" size="small" @click="checkAttachment(scope.row)">查看附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
            </div>
            <el-dialog v-el-drag-dialog  class="dialog-mini" width="400px" @close="closeUpload" style="text-align:center" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
               <div style="display:flex;margin-bottom:10px;">
                   <div class="stext" > <span id="setype">上传类型：</span></div>
                   <div class="elselect"> 
                       <el-select size="mini" v-model="params.belongAppId" filterable @change="upmes" placeholder="请选择上传类型" >
                            <el-option v-for="item in upLoadTypeData" :key="item.dtCode" :label="item.name" :value="item.dtCode" ></el-option>
                        </el-select>
                    </div>
               </div>
                <el-upload drag :file-list="fileList" :data="params"  name="files" :headers="tokenHeader" :action="baseURL +'/Files/Upload'" multiple :before-upload="beforeUpload">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip" style="color:red;">*上传文件不能超过 {{defaultSize}} MB</div>
                </el-upload>
                <div slot="footer" >
                    <el-button size="mini" @click="closeUpload">关闭</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="dialogCheck" width="30%" title="查看附件">
                <img width="100%" :src="dialogImageUrl" alt="">
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogCheck = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>

</template>

<script>
    
    import fileImg from '@/assets/file.png'
    import * as uploadFiles from '@/api/uploadFiles'
    import waves from '@/directive/waves' // 水波纹指令
    import Sticky from '@/components/Sticky'
    import Pagination from '@/components/Pagination'
    import elDragDialog from '@/directive/el-dragDialog'
    import { getToken } from '@/utils/auth'
    import extend from "@/extensions/delRows.js"
    export default {
        name: 'uploadFile',
        components: { Sticky, Pagination, },
        directives: {
            waves,
            elDragDialog
        },
        mixins: [extend],
        data() {
            return {
                aaa:"",
                fileImg:fileImg,//默认文件图标
                tokenHeader: {'X-Token': getToken()},
                baseURL: process.env.VUE_APP_BASE_API, // api的base_url
                baseImgUrl: process.env.VUE_APP_BASE_IMG_URL, // 图片根目录
                multipleSelection: [], // 列表checkbox选中的值
                tableKey: 0,
                list: null,
                total: 0,
                listQuery: { // 查询条件
                    page: 1,
                    limit: 20,
                    key: undefined,
                    appId: undefined,
                    belongAppId:""
                },
                statusOptions: [
                    { key: true, display_name: '停用' },
                    { key: false, display_name: '正常' }
                ],
                params:{
                    belongApp:"",
                    belongAppId:""
                },
                temp: {
                    fileType: '', //文件类型 
                    deleteMark: '', //删除标识 
                    description: '', //描述 
                    belongApp: '', //所属应用 
                    createUserName: '', //上传人姓名 
                    enable: '', //是否可用 
                    fileSize: '', //文件大小 
                    id: '', //Id 
                    filePath: '', //文件路径 
                    extension: '', //扩展名称 
                    thumbnail: '', //缩略图 
                    fileName: '', //文件名称 
                    createUserId: '', //上传人 
                    createTime: '', //上传时间 
                    belongAppId: '', //所属应用ID 
                    sortCode: '', //排序 
                    nothing:''  //代码生成时的占位符，看不顺眼可以删除 
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '编辑',
                    create: '添加'
                },
                dialogPvVisible: false,
                fileList: [],
                rules: {
                    name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
                },
                downloadLoading: false,
                defaultSize:5,//默认文件大小限制
                dialogCheck:false,//查看附件弹窗
                dialogImageUrl:'',//查看图片附件路径
            }
        },
        filters: {
            statusFilter(disable) {
                const statusMap = {
                    false: 'color-success',
                    true: 'color-danger'
                }
                return statusMap[disable]
            }
        },
        computed: {
            upLoadTypeData() {
                return this.$store.state.upLoadTypeData;
            },
        },
        created() {

        },
        mounted() {
            this.getList()
        },
        methods: {
            //查看附件
            checkAttachment(row){
                if(row.fileType != '.jpeg' && row.fileType != '.jpg' && row.fileType != '.png'){
                    var url = this.baseImgUrl + '/'+ row.filePath
                    window.location.href = url
                }else{
                    this.dialogImageUrl = this.baseImgUrl + '/'+ row.filePath;
                    this.dialogCheck = true
                }
            },
            upmes(e){
                this.params.belongApp = this.upLoadTypeData.filter((item)=>{return item.dtCode==e})[0].name
            },
            //上传文件之前
            beforeUpload(file) {
                if(this.params.belongApp==''||this.params.belongAppId==''){
                    this.$message.error("请先选择上传类型在上传")
                    return false
                }
                const isLt2M = file.size / 1024 / 1024 < this.defaultSize;
                    if (!isLt2M) {
                        this.$message.error('上传头像图片大小不能超过 '+ this.defaultSize + 'MB!');
                        return isLt2M;
                    }
            },
            //判断是图片还是文件
            isFile(row){
                const isJPEG =  'jpeg';
                const isJPG =  'jpg';
                const isPNG =  'png';
                const testName = row.fileName.substring(row.fileName.lastIndexOf('.')+1)
                var s = false;
                if(testName != isJPEG && testName != isJPG && testName != isPNG ){
                    s = true
                }else{
                    s = false
                }
                return s;
            },
            rowClick(row) {
                this.$refs.mainTable.clearSelection()
                this.$refs.mainTable.toggleRowSelection(row)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val
            },
            onBtnClicked: function(domId) {
                console.log('you click:' + domId)
                switch (domId) {
                    case 'btnAdd':
                        this.handleCreate()
                        break
                    case 'btnDel':
                        if (this.multipleSelection.length < 1) {
                            this.$message({
                                message: '至少删除一个',
                                type: 'error'
                            })
                            return
                        }
                        this.handleDelete(this.multipleSelection)
                        break
                    default:
                        break
                }
            },
            getList() {
                uploadFiles.getList(this.listQuery).then(response => {
                    this.list = response.data
                    this.total = response.count
                    // 默认图片缩略图处理
                    const isJPEG =  'jpeg';
                    const isJPG =  'jpg';
                    const isPNG =  'png';
                    for (let i = 0; i < this.list.length; i++) {
                        var testName = this.list[i].fileName.substring(this.list[i].fileName.lastIndexOf('.')+1)
                        if(testName != isJPEG && testName != isJPG && testName != isPNG ){
                            this.list[i].thumbnail = fileImg
                        }
                    }
                }).catch(()=>{
                    this.list = []
                    this.total = 0
                })
            },
            handleFilter() {
                this.listQuery.page = 1
                this.getList()
            },
            handleSizeChange(val) {
                this.listQuery.limit = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.listQuery.page = val.page
                this.listQuery.limit = val.limit
                this.getList()
            },
            handleModifyStatus(row, disable) { // 模拟修改状态
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                row.disable = disable
            },
            handleCreate() { // 弹出添加框
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
            },
            closeUpload(){ //关闭上传对话框
                this.fileList =[]
                this.dialogFormVisible = false
                this.getList()
            },
            handleDelete(rows) { // 多行删除
                this.delrows(uploadFiles, rows)
            }
        },
        watch:{
            //处理通过tags关闭页面多选不清空的问题
            '$route'(){
                if(this.$store.state.isLeaveTags == true){
                    this.multipleSelection = [];
                    this.$refs.mainTable.clearSelection()
                    this.$store.state.isLeaveTags = !this.$store.state.isLeaveTags
                }
            }
        }
    }
</script>
<style scoped>
    .dialog-mini .el-select{
        width:100%;
    }
    .stext{
        line-height: 33px;
        width: 20%;
    }
    .elselect{
        width: 80%;
    }
</style>
